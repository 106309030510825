// table 

.mainTableWrapper {
    box-sizing: border-box;

    .mainTable {
        width: 100%;
        max-height: 700px;

        // max-height: 400px;

        // &__note {
            // border: 1px solid magenta;
            // position: relative;
            // background: #ffffffb4;
            // padding: 10px 15px;
            // border-radius: 10px;

            // &::after {
            //     content: "";
            //     position: absolute;
            //     right: 0;
            //     width: 20px;
            //     height: 100%;
            //     background: rgb(255,255,255);
            //     background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(246,246,246,1) 72%);
            // }
        // }


        &__headerTitle {
            font-weight:bold;
        }
    }

    .expandedTableInfo {
        border: none !important;
        
        td {
            padding: 0 !important;
        }
    }

    thead tr th {
        border: none;
        background: #f6f6f6;
    }

    tbody tr {
        // border: none;
        border-bottom: 1px solid rgb(224, 224, 224);
    }
    
    tbody tr:last-child {
        border-bottom: none !important;
    }
    
    tbody tr td {
        padding: 7px 0 7px 20px !important;
        border: none;
        margin: 5px 0;
    }

    tbody tr td:nth-child(4) {
        // background: red !important;
        width: 650px;
    }

}

.actionEditBtn {
    background:#D0E3FA !important;
    width: 33px !important;
    height: 33px !important;
    border-radius: 50% !important;
    padding: 0 !important;
    min-width: 33px !important;
    transition: .2s ease-in-out;
    border: 1px solid transparent !important;

    &:hover {
        border: 1px solid #417BC3 !important;
    }

}

.actionRemoveBtn {
    margin-left: 10px !important;
    background:rgba(225, 81, 65, 0.24) !important;
    width: 33px !important;
    height: 33px !important;
    border-radius: 50% !important;
    padding: 0 !important;
    min-width: 33px !important;
    transition: .2s ease-in-out;
    border: 1px solid transparent !important;

    &:hover {
        border: 1px solid #E15141 !important;
    }
}

.actionsButtons {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
}



.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    max-width: 820px !important;
}


@media(max-width: 610px) {

    .mainTable__headerTitle {
            font-weight:bold;
            font-size: 12px;
            line-height: 15px;
    }

    .mainTable {
        max-height: 550px !important;
    }

    
    .mainTableWrapper {
        padding: 15px !important;

        thead tr th {
            padding: 10px;
            border-bottom: 1px solid #D9D9D9;
        }


        tbody tr td {
                padding: 7px 0 7px 10px !important;
                font-size: 12px;
        }


        tbody tr td:nth-child(4) {
            width: max-content;
        }
    }   

    .actionRemoveBtn {
        margin-left: 5px !important;
    }
}