.changePasswordWrapper {
    // width: 450px !important;
    min-width: 500px !important;
    width: 90%;
    padding: 40px;
}

.changePasswordContent {
    // border: 1px solid blue;
    // width: 300px;
    min-width: 300px;
    // width: 100%;
}


@media(max-width: 640px) {
    .changePasswordWrapper {
        // padding: 20px;
        // padding: 0;;
        min-width: 100% !important;
}
}


@media(max-width: 420px) {
    .changePasswordContent {
        width: 100% !important;
        min-width: max-content;
        // border: 1px solid magenta;
}
}
