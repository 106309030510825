.menuMobileHeader {
    height: 80px;
}

.menuMobile {
    z-index: 100 ;
    display: flex;
    background: #1360aa;
    color: #fff;
    align-items: center;
    padding: 16px;
    position: fixed;
    width: 100%;
    border-radius: 0 0 20px 20px;
    
    &__googleBtn {
        @apply text-dark;
        border-radius: 8px;
        background: #fff;
        padding: 6px 10px;
        display: flex;
        align-items: center;
        font-size: 13px;
        transition: .2s ease-in-out;

        &:hover {
            @apply text-blue;
        }

        img {
        margin-right: 10px;
        width: 20px;
        }
    }


    &__items {
        width: 100%;
        background: #417BC3;
        position: fixed;
        bottom: 0;
        z-index: 100;
        display: flex;
        color: #fff;
        font-size: 13px;
        padding: 0 33px;
        justify-content: space-between;
        border-radius: 20px 20px 0 0;
    }
    
    &__item {
        padding: 19px 0 30px 0;
        display: flex;
        align-items: center;
    
        img {
            width: 16px;
            margin-right: 8px;
        }
    }

    &__item--disabled {
        pointer-events: none;
        opacity: .2;
    }

    &__item.active {

        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 4px;
            border-radius: 0 0 4px 4px;
            background: #A8C9F2;
        }
    }
}

.fixed_feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #417BC3;

    & > object {
        margin-left: 3px;
    }
}