.home {
    &__userDataFrom {
        width: 100%;
        max-width: 900px;
        margin-top: 20px;
    }


    &__editorWrapper {
        margin-top: 30px;
    }

    &__input {
        flex: 1;
    }

    &__chartBtn {
        @apply py-1 px-5 rounded border border-blue bg-white text-blue text-sm sm:text-base transition cursor-pointer leading-4 text-center;
    }

    &__chartBtn--active {
        @apply py-1 px-5 rounded border border-blue bg-blue text-white text-sm sm:text-base transition cursor-pointer leading-4 text-center;
    }
}
 

.actual_dot {
    background-color: rgb(53, 162, 235);
    width: 15px;
    height: 15px;
    border-radius: 100%;
    // display: inline-block;
    flex-shrink: 0
}

.predicted_dot {
    background-color: rgba(255, 99, 132, 1);
    width: 15px;
    height: 15px;
    border-radius: 100%;
    // display: inline-block;
    flex-shrink: 0
}

.avarage_dot {
    background-color: rgba(146, 173, 203, 1);
    width: 15px;
    height: 15px;
    border-radius: 100%;
    // display: inline-block;
    flex-shrink: 0
}

.max-w {
    max-width: 500px;
}


.MuiButtonBase-root.Mui-disabled > div{
    background: #cccccc !important;
}

.roundedMaterial {
    border-radius: 50px !important;
}

.mainContainer {
    @apply bg-lightGray;
    padding: 24px 48px;
    width: 100%;
    border-radius: 24px;
}


@media(max-width: 996px) {
    .mainContainer{
        padding: 20px;
    }
}

.chart_blok {
    // max-width: calc(100vw - 150px);
    max-height: 50vh;
    min-height: 350px;
}