body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.absoluteCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-size: 30px;
  color: #333333;
  padding: 20px 0;
  font-weight: 700;
}


.subtitle {
  font-size: 24px;
  color: #333333;
  margin-bottom: 10px;
  font-weight: 700;
}

.blueGradient {
  background: linear-gradient(101.1deg, #3972B9 6.5%, #265CA0 83.86%);
}

.mainInput {
  background: white;
  border-radius: 30px;
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 30px;
    border: 1px solid #ECECEC;
  }
  .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #3972B9;
  }

  label {
    color: #9C9C9C;
  }

  input {
    background: white;
    border-radius: 30px;
  }
}

.mainInput.plain {
  border-radius: 4px; 
  .MuiOutlinedInput-notchedOutline {
    border-radius: 4px;
  }
}
// }
// popups with text editor 

.popup {

  .ql-toolbar.ql-snow {
    border-radius: 5px !important;
    background: #cde0f7c5 !important;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-radius: 5px;
  }
  .ql-container.ql-snow {
    border: 1px solid #c4c4c4 !important;
  }
}

.ql-toolbar.ql-snow {
  border: none !important;
  background: #CDE0F7 !important;
  border-radius: 48px !important;
  padding: 14px 24px !important;
        // width: 550px !important;


  .ql-formats:first-child  {
      margin-right: 10px !important;

      .ql-header.ql-picker {
          border: 1px solid #b2c6de !important;
          border-radius: 4px !important;
          padding: 3px 0 !important;
          height: auto !important;
          box-sizing: border-box !important;
      }

      .ql-header.ql-picker.ql-expanded > .ql-picker-label {
          border: 1px solid transparent;
      }


      // color
      .ql-color.ql-picker.ql-color-picker {
          border: 1px solid #b2c6de !important;
          border-radius: 4px !important;
          padding: 3px 0 !important;
          height: auto !important;
          margin-left: 10px;
          line-height: 14px;
          box-sizing: border-box !important;

      }


      .ql-color.ql-picker.ql-color-picker.ql-expanded > .ql-picker-label {
          border: 1px solid transparent;
      }
  }

}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  margin-top: 12px !important;
  border-radius: 30px;
  background: #fff;
}

.ql-container.ql-snow {
  border: 1px solid #eee !important;
  height: 100px !important;
}

.ql-editor {
  padding: 20px !important;
  font-size: 15px !important;
}

.ql-editor.ql-blank::before {
  font-style: normal!important;
}

// animation when we switching between pages

.fadeIn {
  animation: fadeIn .2s ease-in-out;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


// popups 

.MuiDialog-container .MuiPaper-root {
  box-shadow: none !important;
  // background-color: transparent !important;
}

.MuiPaper-root {
  background-color:transparent;
}

.MuiPaper-root .MuiDialogContent-root.dialog {
  background-color: #ffffff;
  border-radius: 40px
}

.MuiBackdrop-root {
  @apply bg-popupBackground #{!important};
  backdrop-filter: blur(2px);
}

.MuiDialog-paper {
  border-radius: 40px !important;
  // width: fit-content !important;
}

.dialog .MuiDialogContent-root {
  &:first-child {
     padding: 0;
  }
}
.dialog .MuiDialogContent-root {
  &:nth-child(2){
     border-radius: 12px !important;
  }
}


.dialog--blurred {
  backdrop-filter: blur(5px) !important;
}


// errors
.text--error {
  color: #E63946;
  font-size: 12px;
}

.auth__login--error {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}



// MEDIA

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}


// checkboxes
.defaultCheckbox {
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  color: #707070 !important;
  padding: 0px 20px 0px 10px;
  margin-left: 0px !important;
  margin-right: 0px !important;

  span {
    padding: 5px;
  }
  
  span:last-child {
      margin-left: 5px;
  }

  &:hover {
      border: 1px solid #417cc3a9;
  }
}

.defaultCheckbox--checked {
  @extend .defaultCheckbox;
  background: #417cc318;
  border: 1px solid #417cc3a9;

  span:last-child {
      color: #417cc3 !important;
  }
}

// media

@media(max-width: 640px) {
  .title {
    font-size: 24px;
    padding: 12px 0;
  }
  
  
  .subtitle {
    font-size: 18px;
    margin-bottom: 5px;
  }


  //EDITOR
  .popup {
    .ql-container.ql-snow {
      border-radius: 5px !important;
    }
  }

  .ql-toolbar.ql-snow {
    border-radius: 20px !important;
  }

  .ql-container.ql-snow {
    border-radius: 20px !important;
  }

  // popups

  .MuiDialog-paper {
    border-radius: 16px !important;
  }

  .MuiPaper-root .MuiDialogContent-root.dialog {
    border-radius: 16px
  }

}