.menuWrapper {
    @apply bg-lightBlue;
    width: 260px;
    min-width: 200px;
    min-height: 100vh;

}

p.no-wrap {
    white-space: nowrap;
    text-align: center;
    margin: 0 !important;
    font-size: 14px;
}

.menu {
    background-color: rgb(19, 96, 170);
    height: 100%;
    width: 260px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    &__items {
        border-top-right-radius: 40px;
        @apply bg-blue;
        padding: 45px 12px;
        width: 100%;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        color: #fff;
        flex: 1;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 12px 32px;
        margin-top: 10px;
        border-radius: 40px;
        cursor: pointer;
        transition: .2s ease-in-out;

        &:hover {
            @apply bg-transparentBlue;
        }

        img {
            margin-right: 24px;
        }
    }

    &__item_double {
        display: flex;
        padding: 12px 32px;
        margin-top: 10px;
        border-radius: 40px;
        transition: .2s ease-in-out;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        width: 100%;;

        &_button {
            display: flex;
            padding: 6px 10px;
            margin-top: 10px;
            border-radius: 20px;
            cursor: pointer;
            transition: .2s ease-in-out;

            &:hover {
                @apply bg-transparentBlue;
            }
        }
    }

    &__item_double_mob {
        display: flex;
        border-radius: 40px;
        transition: .2s ease-in-out;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        width: 100%;;

        &_button {
            display: flex;
            padding: 6px 10px;
            margin-top: 10px;
            border-radius: 20px;
            cursor: pointer;
            transition: .2s ease-in-out;

            &:hover {
                @apply bg-transparentBlue;
            }
        }
    }

    &__item.active {
        @extend .menu__item;
        @apply bg-lightBlue;
        pointer-events: none;
    }

    &__item--disabled {
        // pointer-events: none;
        opacity: 0.3;
    }

    &__logoutBtn {
        display: flex;
        align-items: center;
        padding: 24px ;
        border-radius: 24px;
        margin: auto 32px 0 32px;
        background: #21569A;
        transition: .2s ease-in-out;
        cursor: pointer;

        &:hover {
            background: darken($color: #21569A, $amount: 1)
        }
        img {
            margin-right: 24px;
        }
    }

    &__loginButtons {
        margin-top: 60px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 7px;
    }
        
    &__loginBtn {
        // width: 100%;
        // @apply border border-transparentWhite;
        // @apply text-pastelBlue;
        // font-size: 14px;
        // transition: .2s ease-in-out;
        // padding: 5px 10px;
        // border-radius: 8px;
        // display: flex;
        // justify-content: center;
        // cursor: pointer;

        // div {
        //     margin-right: 5px;
        // }

        // &:hover {
        //     @apply text-white;
        //     @apply border border-white;
        // }
    }
    
    &__googleBtn {
        @apply text-dark;
        border-radius: 8px;
        background: #fff;
        margin-top: 20px;       
        padding: 10px;
        display: flex;
        align-items: center;
        font-size: 15px;
        transition: .2s ease-in-out;

        &:hover {
            @apply text-blue;
        }
  
        img {
           margin-right: 10px;
           width: 20px;
        }
     }

     &__googleBtn--mobile {
        background: rgb(255, 255, 255);
        border-radius: 4px;
        width: 100%;
        justify-content: center;
        img {
            margin: 0;
         }
     }
}

@media(max-width: 996px) {
    .menuWrapper {
        width: 230px;
        min-width: 200px;
    }
    
    .menu {
        width: 230px;
        position: fixed;
    }    
}

@media(max-width: 820px) {

    .menuWrapper {
        width: 90px;
        min-width: 90px;
    }

    .menu {
        width: 90px;
        min-width: 90px;

        &__loginBtn {
            font-size: 12px;
            flex-direction: column;

            div {
                margin-right: 0px;
            }
        }


        &__item {
            padding: 10px;
            justify-content: center;
    
            img {
                margin-right: 0;
            }

            p {
                display: none;
            }
        }

        &__logoutBtn {
            padding: 14px;
            margin: auto 0 0 0;
            justify-content: center;

            img {
                margin-right: 0;
            }

            p {
                display: none;
            }
        }
        
    }
}